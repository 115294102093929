/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 64.1.2-v202403201906
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqContextApiClass {
 
   constructor() {}

  /**
   * @summary Create a new comment associated with an Item
   * @param {string} itemId - ID of the item that the comment is associated with
   */
  public createContextComment(
    body: models.ContextCommentInputV1,
    {
      itemId
    } : {
      itemId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/comments`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ContextCommentOutputV1>;
  }

  /**
   * @summary Create a new label associated with an item
   * @param {string} itemId - ID of the item that the label is associated with
   */
  public createContextLabel(
    body: models.ContextLabelInputV1,
    {
      itemId
    } : {
      itemId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/labels`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ContextLabelOutputV1>;
  }

  /**
   * @summary Create new opaque context associated with an item
   * @param {string} itemId - ID of the item that the opaque context is associated with
   */
  public createContextOpaque(
    body: models.ContextOpaqueInputV1,
    {
      itemId
    } : {
      itemId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/opaque`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ContextOpaqueOutputV1>;
  }

  /**
   * @summary Create a new label
   */
  public createLabel(
    body: models.LabelInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/labels`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.LabelOutputV1>;
  }

  /**
   * @summary Create a new label category
   */
  public createLabelCategory(
    body: models.LabelCategoryInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/labels/categories`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.LabelCategoryOutputV1>;
  }

  /**
   * @summary Delete or archive a comment
   * @param {string} itemId - ID of the item that the comment is associated with
   * @param {string} commentId - The comment ID to be deleted
   * @param {boolean} [_delete=false] - Permanently and irretrievably delete the comment
   */
  public deleteContextComment(
    {
      itemId,
      commentId,
      _delete
    } : {
      itemId: string,
      commentId: string,
      _delete?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    if (isNil(commentId)) {
      throw new Error("'commentId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/comments/${encodeURIComponent(String(commentId))}`,

      params: omitBy({
        ['delete']: _delete
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Delete or archive a label
   * @param {string} itemId - ID of the item that the label is associated with
   * @param {string} labelId - The context label ID to be deleted
   */
  public deleteContextLabel(
    {
      itemId,
      labelId
    } : {
      itemId: string,
      labelId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    if (isNil(labelId)) {
      throw new Error("'labelId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/labels/${encodeURIComponent(String(labelId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Delete or archive opaque context
   * @param {string} itemId - ID of the item that the opaque context is associated with
   * @param {string} opaqueId - The opaque ID to be deleted
   * @param {boolean} [_delete=false] - Permanently and irretrievably delete the opaque context
   */
  public deleteContextOpaque(
    {
      itemId,
      opaqueId,
      _delete
    } : {
      itemId: string,
      opaqueId: string,
      _delete?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    if (isNil(opaqueId)) {
      throw new Error("'opaqueId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/opaque/${encodeURIComponent(String(opaqueId))}`,

      params: omitBy({
        ['delete']: _delete
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Delete a specific label.
   * @param {string} labelId - ID of the label
   */
  public deleteLabel(
    {
      labelId
    } : {
      labelId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(labelId)) {
      throw new Error("'labelId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/context/labels/${encodeURIComponent(String(labelId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Delete a specific label category.
   * @param {string} categoryId - ID of the category
   */
  public deleteLabelCategory(
    {
      categoryId
    } : {
      categoryId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(categoryId)) {
      throw new Error("'categoryId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/context/labels/categories/${encodeURIComponent(String(categoryId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Get all label categories
   */
  public findLabelCategories(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/context/labels/categories`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.LabelCategoryOutputListV1>;
  }

  /**
   * @summary Get all labels for a given category
   * @param {string} categoryId - ID of the category of labels to return
   * @param {string} [query] - Search for labels that contain this string in a case-insensitive manner. This is especially useful for autocomplete categories. Note that if this is specified only the first 20 results will be returned, ordered by name
   * @param {string} [parentId] - If set to the ID of a parent label then the child labels will be returned, if set to the empty GUID (00000000-0000-0000-0000-000000000000) only top level labels will be returned, if not specified all labels will be returned
   */
  public findLabels(
    {
      categoryId,
      query,
      parentId
    } : {
      categoryId: string,
      query?: string,
      parentId?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(categoryId)) {
      throw new Error("'categoryId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/context/labels/${encodeURIComponent(String(categoryId))}`,

      params: omitBy({
        ['query']: query,
        ['parentId']: parentId
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.LabelOutputListV1>;
  }

  /**
   * @summary Update a comment
   * @param {string} itemId - ID of the item that the comment is associated with
   * @param {string} commentId - ID of the comment to update
   */
  public updateContextComment(
    body: models.ContextCommentInputV1,
    {
      itemId,
      commentId
    } : {
      itemId: string,
      commentId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    if (isNil(commentId)) {
      throw new Error("'commentId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/comments/${encodeURIComponent(String(commentId))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ContextCommentOutputV1>;
  }

  /**
   * @summary Update opaque context
   * @param {string} itemId - ID of the item that the opaque context is associated with
   * @param {string} opaqueId - ID of the opaque context to update
   */
  public updateContextOpaque(
    body: models.ContextOpaqueInputV1,
    {
      itemId,
      opaqueId
    } : {
      itemId: string,
      opaqueId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    if (isNil(opaqueId)) {
      throw new Error("'opaqueId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/${encodeURIComponent(String(itemId))}/opaque/${encodeURIComponent(String(opaqueId))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ContextOpaqueOutputV1>;
  }

  /**
   * @summary Update a label
   * @param {string} labelId
   */
  public updateLabel(
    body: models.LabelInputV1,
    {
      labelId
    } : {
      labelId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(labelId)) {
      throw new Error("'labelId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/labels/${encodeURIComponent(String(labelId))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.LabelOutputV1>;
  }

  /**
   * @summary Update a label category
   * @param {string} categoryId
   */
  public updateLabelCategory(
    body: models.LabelCategoryInputV1,
    {
      categoryId
    } : {
      categoryId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(categoryId)) {
      throw new Error("'categoryId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/context/labels/categories/${encodeURIComponent(String(categoryId))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.LabelCategoryOutputV1>;
  }

}


export const sqContextApi = new sqContextApiClass();
